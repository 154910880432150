import React, { Component } from 'react';

import classes from './Calendar.module.css';

import { connect } from 'react-redux';
import axios from '../../axiosdata';
import * as actions from '../../store/actions/index';

import InfoPopup from './InfoPopup';
import BackdropBlack from '../../UI/Backdrop/BackdropBlack';
import NextButton from '../../UI/Buttons/NextButton';
import PrevButton from '../../UI/Buttons/PrevButton';
import thunk from 'redux-thunk';

class CalendarMobile extends Component{

    state = {
        programs: null,
        contentMobile: <div></div>,
        differentDaysArray: null,
        arrayOfDayArrays: null,
        finalSortedArray: null,
        arrayOfArraysWith3: null,
        arrayOfArraysOf3WithContent: null,
        times: null,
        showInfoPopup: false,
        program: null,
        mapArrayMobile: null,
        showArray: 0,
        displayArray: [],
        currentProgram: {
            programName: 'Ei ohjelmaa',
            groupName: ' ',
            date: ' ',
            startTime: ' ',
            end_time: ' ',
            info: ' '
        }
    }

    componentDidMount = () => {
        this.setShowArrayIndexAccordingToDate();
        this.getData();
    }

    setShowArrayIndexAccordingToDate = () => {
        let date = new Date();

        if(date.getDay() >= 2 && date.getDay() <= 4){
            this.setState({showArray: 1})
        } else if(date.getDay() >= 5 && date.getDay() <= 6){
            this.setState({showArray: 2})
        }

    }

    setCurrentProgram = () =>{
        this.props.onChange(this.state.currentProgram)
    }

    getData = () => {
        const programs = []; 
        axios.get('/programs.json')
        .then(res => {
            for (let key in res.data){
                programs.push({
                    ...res.data[key],
                    id: key,
                });
            }
            this.setState({programs: programs});
            this.orderData();
        })
        .catch(err => console.log(err));    
    }

    orderData = () => {
        let programs = this.state.programs;
        let sortedData = programs.sort(function(a,b){
            return new Date(a.program.date).getTime() - new Date(b.program.date).getTime();
          });
        this.setState({programs: sortedData})
        this.getDays();
    }

    getDays = () => {
        let differentDaysArray = [];
        let programs = this.state.programs;
        for(let i=0; i < programs.length - 1; i++){
            if(programs[i].program.date !== programs[i+1].program.date){
                differentDaysArray.push(programs[i].program.date)
            }
        }
        if(programs[programs.length-1].program.date !== programs[programs.length - 2].program.date){
            differentDaysArray.push(programs[programs.length-1].program.date)
        } else {
            differentDaysArray.push(programs[programs.length-2].program.date)
        }
        this.setState({differentDaysArray: differentDaysArray});
        this.createDayArrays();
    }
    
    createDayArrays = () => {
        let differentDaysArray = this.state.differentDaysArray;
        let arrayOfDayArrays = [];
        for(let i = 0; i < differentDaysArray.length; i++) {
            arrayOfDayArrays.push([]);
        }
        this.setState({arrayOfDayArrays: arrayOfDayArrays});
        this.createDayArraysWithContent();
    }

    createDayArraysWithContent = () => {
        let arrayOfDayArrays = this.state.arrayOfDayArrays;
        let programs = this.state.programs;
        arrayOfDayArrays[0].push(programs[0]);
        let y = 0;
        for(let i=0; i < programs.length-1; i++){
            if( programs[i+1].program.date === programs[i].program.date){
                arrayOfDayArrays[y].push(programs[i+1])
    
            } else {
                y++;
                arrayOfDayArrays[y].push(programs[i+1])
            }
        }
        this.setState({arrayOfDayArrays: arrayOfDayArrays})
        this.sortDayArrayswithContent();
    }

    sortDayArrayswithContent = () => {
        let dayArraysWithContent = this.state.arrayOfDayArrays;
        let finalSortedArray = [];
        for(let i=0; i < dayArraysWithContent.length; i++){
            finalSortedArray.push(
                dayArraysWithContent[i].sort(function(a,b){
                      return parseInt(a.program.startTime.slice(0, 2)) - parseInt(b.program.startTime.slice(0, 2));
                    })
            )
        }
        this.setState({finalSortedArray: finalSortedArray})
        this.createArraysThatFit3();
    }

    createArraysThatFit3 = () =>{
        let finalSortedArray = this.state.finalSortedArray;
        let arrayOfArraysWith3 = [];
        let length = (finalSortedArray.length)/3;

        for(let i = 0; i < Math.ceil(length); i++) {
            arrayOfArraysWith3.push([]);
        }

        this.setState({arrayOfArraysWith3: arrayOfArraysWith3})
        this.divideFinalArrayToArraysWith3();
    }

    divideFinalArrayToArraysWith3 = () => {
        let finalSortedArray = this.state.finalSortedArray;
        let arrayOfArraysOf3WithContent = this.state.arrayOfArraysWith3;
        for(let i=0; i < arrayOfArraysOf3WithContent.length; i++){
            for(let j= 3*i; j < (3*i)+3; j++){
                if(j >= finalSortedArray.length){
                    break;
                } 
                arrayOfArraysOf3WithContent[i].push(finalSortedArray[j])
            }
        }
        this.setState({arrayOfArraysOf3WithContent: arrayOfArraysOf3WithContent})
        this.createMapArraysMobile();
        this.getCurrentProgram();
    }
    

    createMapArraysMobile = () => {
        let array = this.state.arrayOfArraysOf3WithContent;
        let mapArrayMobile = [];
        for(let i = 0; i < array.length; i++) {
            mapArrayMobile.push([]);
        }
        this.setState({mapArrayMobile: mapArrayMobile});
        this.createFinalMapArrayMobile();
    }

    createFinalMapArrayMobile = () => {
        let array = this.state.arrayOfArraysOf3WithContent;
        let mapArrayMobile = this.state.mapArrayMobile;
        for(let i=0; i < mapArrayMobile.length; i++){
            mapArrayMobile[i].push(array[i]);
        }
        this.setState({mapArrayMobile: mapArrayMobile});
        this.mapData(this.state.showArray)
    }

    getWeekDay = (date) => {
        const days = ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'];
        const weekDay = new Date(date).getDay();
        return days[weekDay];
    };
    
    calculateHeight = (startTime, endTime) => {
        let heightName = null;
        let time = parseInt(endTime.slice(0, 2)) - parseInt(startTime.slice(0, 2))
        if(time === 1){
            heightName = 'onehour'
        }
        if(time === 2){
            heightName = 'twohours'
        }
        if(time === 3){
            heightName = 'threehours'
        }
        if(time === 4){
            heightName = 'fourhours'
        }
        if(time === 5){
            heightName = 'fivehours'
        }
        if(time === 6){
            heightName = 'sixhours'
        }
        return heightName;
    }

    openInfoPopup = (program) => {
        this.state.showInfoPopup ? this.setState({showInfoPopup: false}) : this.setState({showInfoPopup: true});
        this.setState({program: program})
    }

    compareToCurrentDate = (date) => {
        let name ='';
        let compareDate = new Date(date);
        let currentDate = new Date();
        if(currentDate.toDateString() === compareDate.toDateString()){
            name = 'currentDay';
        } 
        return name;
    }

    getCurrentProgram = () => {
        let array =  this.state.programs
        let currentDate = new Date();
        let programStart = new Date();
        let programEnd = new Date();

        for (let i=0; i < array.length; i++) {
      /*       if((currentDate.toDateString() === new Date(array[i].program.date).toDateString()) && (currentDate.getHours() >= parseInt(array[i].program.startTime) && currentDate.getHours() <= parseInt(array[i].program.end_time))){
                this.setState({currentProgram: array[i].program})
            } */
            if(
                (currentDate.toDateString() === new Date(array[i].program.date).toDateString()) &&
                (currentDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0) >= programStart.setHours(parseInt(array[i].program.startTime),parseInt(array[i].program.startTime.slice(3,5)), 0))
            && (currentDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0) < programEnd.setHours(parseInt(array[i].program.end_time),parseInt(array[i].program.end_time.slice(3,5)), 0))
            ){
                this.setState({currentProgram: array[i].program})
            }
        }
        this.setCurrentProgram();
    }

    nextMobile = () => {
        if(this.state.showArray < (this.state.mapArrayMobile.length -1)){
            this.mapData(this.state.showArray + 1);
            this.setState({showArray: this.state.showArray +1})
        }
    }

    previousMobile = () => {
        if(this.state.showArray > 0){
            this.mapData(this.state.showArray - 1);
            this.setState({showArray: this.state.showArray - 1})
        } 
    }
    
    mapData = (arrayIndex) => {
        
        let contentMobile = (
            <div className={classes.calendarWrapperMobile}>
                {this.state.mapArrayMobile[arrayIndex].map((calendar, index) => (
                <div className={classes.calendar} key={index + Math.random() * 1000}>
                    <div className={classes.calendarInner} key={index + Math.random() * 1000}>
                        {calendar.map((day, index) => (
                                <div className={classes.day} key={index + Math.random() * 1000}>
                                <div className={[classes.header, classes[this.compareToCurrentDate(day[0].program.date)]].join(' ')}>
                                    <h2>{this.getWeekDay(day[0].program.date)}</h2>
                                    <h2>{day[0].program.date.slice(8,10)}.{day[0].program.date.slice(5,7)}</h2>
                                </div>
                                {day.map((program,index) => (
                                    <div className={[classes.program, classes[this.calculateHeight(program.program.startTime, program.program.end_time)]].join(' ')} onClick={()=>this.openInfoPopup(program)} key={index + Math.random() * 1000}>
                                        <h5>{program.program.startTime}-{program.program.end_time}</h5>
                                        <h3>
                                            {program.program.programName}
                                        </h3>
                                        <h3>
                                            {this.calculateHeight(program.program.startTime, program.program.end_time) === "onehour" ?  ' ' : program.program.groupName}
                                        </h3>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    </div>
                ))}
            </div>
        )
        this.setState({contentMobile: contentMobile})
    }

    render() {
        let popup = null;
        if(this.state.showInfoPopup) {
            popup = (
               <div><InfoPopup program={this.state.program} isAuthenticated={this.props.isAuthenticated}/><BackdropBlack show={this.state.showInfoPopup} clicked={this.openInfoPopup}/></div>
            )
        }
        return(
            <div>
                <div className={classes.mobile}>
                    <PrevButton click={this.previousMobile}>Edellinen</PrevButton>
                    <NextButton click={this.nextMobile}>Seuraava</NextButton>
                </div>
                {popup}
                {this.state.contentMobile}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.token !== null,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChange: (program) => dispatch(actions.setCurrentProgram(program))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(CalendarMobile);