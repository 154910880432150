import React from 'react';

import Logo from '../../Assets/Logos/google16.png'; 
import './SettingsIcon.module.css'

const background = (props) => (
    <div className="WhiteLogo">
        <img src={Logo} alt="Background"/>
    </div>
);

export default background; 