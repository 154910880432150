import * as actionTypes from '../actions/actionTypes';

import  { updateObject } from '../../shared/uitility';

const intialState = {
    play: false,
    program: {
        programName: 'Ei ohjelmaa',
        groupName: '',
        date: ' ',
        startTime: ' ',
        end_time: ' ',
        info: ' '
    }
};

const setPlay = (state, action) => {
    return updateObject(state, { play: action.play});
};

const setCurrentProgram = (state, action) => {
    return updateObject(state, { program: action.program });
};


const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.PLAY: return setPlay(state, action);
        case actionTypes.SET_PROGRAM: return setCurrentProgram(state, action);
        default:
            return state;      
    };
};

export default reducer;