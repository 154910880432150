import React from 'react';

import classes from './Button.module.css';


const button = (props) => (
    <div className={classes.Playbutton_background}>
        <button
        disabled={props.disabled}
        className={classes.Pausebutton}
        onClick={props.click}>{props.children}
        </button>
    </div>
    
)

export default button;