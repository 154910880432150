import React, { Component } from 'react';

import classes from './DataUploader.module.css';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Buttons/Button';
import axios from '../../axiosdata';
import { updateObject, checkValiditation } from '../../shared/uitility';
import { connect } from 'react-redux';


class DataUploder extends Component {
    
    state = {
        programForm: {
            programName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Ohjelman nimi'
                },
                value: '',
                validation: {
                    rquired: true,
                    minLength: 0
                },
                valid: false,
                touched: false
            },
            groupName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Ryhmän nimi'
                },
                value: '',
                validation: {
                    rquired: false,
                    minLength: 0
                },
                valid: false,
                touched: false
            },
            date: {
                elementType: 'input',
                elementConfig: {
                    type: 'date',
                    placeholder: 'Päivämäärä'
                },
                value: '',
                validation: {
                    rquired: true,
                    minLength: 0
                },
                valid: false,
                touched: false
            },
            startTime: {
                elementType: 'input',
                elementConfig: {
                    type: 'time',
                    placeholder: 'Ohjelma alkaa'
                },
                value: '',
                validation: {
                    rquired: true,
                    minLength: 2
                },
                valid: false,
                touched: false
            },
            end_time: {
                elementType: 'input',
                elementConfig: {
                    type: 'time',
                    placeholder: 'Ohjelma loppuu'
                },
                value: '',
                validation: {
                    rquired: true,
                    minLength: 2
                },
                valid: false,
                touched: false
            },
            info: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Lisätietoa ohjelmasta'
                },
                value: '',
                validation: {
                    rquired: true,
                    minLength: 0
                },
                valid: false,
                touched: false
            },
        },
        formIsValid: false,
        disabled: true,
        missingInfo: false
    }

    inputChangedHandler = (event, inputIdentifier) => {
            console.log(inputIdentifier)
            const updatedFormElement = updateObject(this.state.programForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValiditation(event.target.value, this.state.programForm[inputIdentifier].validation),
            touched: true
        });
        
        const updatedBooksForm = updateObject(this.state.programForm, {
            [inputIdentifier]: updatedFormElement
        });
        
        let formIsValid = true;
        for (let inputIdentifier in updatedBooksForm) {
            formIsValid = updatedBooksForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({programForm: updatedBooksForm, formIsValid: formIsValid});
        
    }


    saveInformationToFireBase = (event) =>{
        event.preventDefault();
        if(this.state.formIsValid){
            this.SaveBookToFireBase();
        } else {
            this.setState({missingInfo: true})
        }

    }

    SaveBookToFireBase = () => {
        const formData = {
            program: {}
        };
        for (let formElementIndentifier in this.state.programForm) {
            formData.program[formElementIndentifier] = this.state.programForm[formElementIndentifier].value;
        }

        axios.post('/programs.json?auth=' + this.props.token , formData)
        .then(res => window.location.reload(false))
        .catch(err => console.log(err));
    } 

    disableButton = () =>{
        this.setState({disabled: false})
    }

    render () {
        const formElementsArray = [];
        let missingInfoWarning = '';
        if (this.state.missingInfo){
            missingInfoWarning = <div className={classes.warning}>Anna kaikki tarvittavat tiedot!</div>;
        }
            
        for (let key in this.state.programForm) {
            formElementsArray.push({
                id: key,
                config: this.state.programForm[key]
            });
        }
        let form = (
            <div>   
                <form>
                {formElementsArray.map(formElement =>(
                    <div>   
                    
                    <Input 
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        label={formElement.config.elementConfig.placeholder}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                        </div>
                ))}
                {missingInfoWarning}
                </form>  
                    <Button click={this.saveInformationToFireBase}>Tallenna</Button>
                    <Button click={this.props.cancel}>Peruuta</Button>  

            </div>
        );

        return (
                <div className={classes.programData}>
                    <div className={classes.Title}>
                        <h2>Lisää ohjelma</h2>
                    </div>
                        {form}
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
    }
}

export default connect(mapStateToProps)(DataUploder);
