import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './Player.module.css';
import * as actions from '../../store/actions/index';
import Playbutton from '../../UI/Buttons/Playbutton';
import Pausebutton from '../../UI/Buttons/Pausebutton';
import CallStudio from '../Call/CallStudioPlayer';

class Player extends Component {

    state = {
        play: true,
        showInfo: false
    }

    customPlay = () => {
        this.props.onChange(true);
        this.state.play ? this.setState({play: false}) : this.setState({play: true}) 
    }
    customStop = () => {
        this.props.onChange(false);
        this.state.play ? this.setState({play: false}) : this.setState({play: true}) 
    }

    showInfo = () => {
        this.state.showInfo ? this.setState({showInfo: false}) : this.setState({showInfo: true})
    }

    render(){
        return(
            <div>
                <div className={classes.background}>
                    <div className={classes.Player}>

                        <div className={classes.Play}>
                            {this.state.play ? <Playbutton click={this.customPlay}/> : <Pausebutton click={this.customStop}/>}
                        </div>
                        <div>
                            <CallStudio/>
                        </div>
                        <div className={classes.infobox}>

                            <div className={classes.program}>
                                <h2>Studiossa:</h2>
                                <marquee className={classes.text}>
                                    {this.props.currentProgram.programName} {this.props.currentProgram.groupName ? '-' : ' '} {this.props.currentProgram.groupName}
                                </marquee>
                            </div>

                        {/*     { this.state.showInfo ? 
                            <div className={classes.moreInfo}>
                                <h2>Aika:</h2>         
                                <div className={classes.text}>
                                    {this.props.currentProgram.startTime} {this.props.currentProgram.groupName ? '-' : ' '} {this.props.currentProgram.end_time}
                                </div>       
                                <h2>Lisätietoa:</h2>         
                                <div className={classes.text}>
                                    {this.props.currentProgram.info}
                                </div>       
                            </div>
                            : null }

                            {this.state.showInfo ?  <div className={classes.arrowContainer} onClick={this.showInfo}><div className={classes.arrowUp}/></div> :<div className={classes.arrowContainer} onClick={this.showInfo}><div className={classes.arrowDown} /></div>} */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
       play: state.play.play,
       currentProgram: state.play.program
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChange: (play) => dispatch(actions.changeStatePlay(play))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Player);