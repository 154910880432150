import React, { Component } from 'react';

import classes from './AdminSettingsDropdown.module.css';

import SettingsIcon from '../../Assets/Logos/001-settings.png'
import DropdownButton from '../../UI/Buttons/DropdownButton';
import SettingsButton from '../../UI/Buttons/SettingsButton';
import Backdrop from '../../UI/Backdrop/Backdrop';

import { connect } from 'react-redux';

class AdminSettingsDropdown extends Component{

    state= {
        add: this.props.add,
        delete: this.props.delete,
        click: this.props.click,
        showShoutbox: true,
        id: null
    }

  
    render() {
        let dropdownSponsors = (
            <div>
            <div className={classes.dropdownElement}>
                        <DropdownButton click={this.props.add}>Lisää</DropdownButton>
                        <DropdownButton click={this.props.delete}>Poista</DropdownButton>
            </div>
            <Backdrop show={this.props.showSponsorsSettings} clicked={this.props.click}>
            </Backdrop>

            </div>
        );
        let dropdownShoutbox = (
            <div>
                <div className={classes.dropdownElement}>
                    <DropdownButton click={this.props.open}>Avaa Shoutbox</DropdownButton>
                    <DropdownButton click={this.props.close}>Sulje Shoutbox</DropdownButton>
                </div>
                <Backdrop show={this.props.showShoutboxSettings} clicked={this.props.click}>
                </Backdrop>
            </div>
        );
        let dropdownCalendar = (
            <div>
                <div className={classes.dropdownElement}>
                    <DropdownButton click={this.props.addProgram}>Lisää ohjelma</DropdownButton>
                </div>
                <Backdrop show={this.props.showCalendarSettings} clicked={this.props.click}>
                </Backdrop>
            </div>

        );

        return(
            <div className={classes.dropdown}>
                <SettingsButton click={this.props.click}>
                    <img src={SettingsIcon} alt=""/>
                </SettingsButton>
                {this.props.showSponsorsSettings ? dropdownSponsors : null}
                {this.props.showShoutboxSettings ? dropdownShoutbox : null}
                {this.props.showCalendarSettings ? dropdownCalendar : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
    }
}


export default connect(mapStateToProps)(AdminSettingsDropdown);