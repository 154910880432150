import React, { Component } from 'react';

import OpenButton from '../../UI/Buttons/OpenButton';
import CloseButton from '../../UI/Buttons/CloseButton';

import { connect } from 'react-redux';
import axios from '../../axiosdata';


import classes from './ShoutboxControls.module.css';

class ControlShoutbox extends Component {

    state = {
        showShoutbox: true,
        id: null
    }
    componentDidMount() {
        this.getdata();
    }

    getdata = () => {
        let dataId = ''; 
        axios.get('/shoutbox.json')
        .then(res => dataId = res.data.name)
        .catch(err => console.log(err));
        this.setState({id: dataId});
    }


/*     saveToFirebase = () => {
        const data = {
            show: this.state.showShoutbox
        }

        axios.post('/shoutbox.json?auth=' + this.props.token , data)
        .then(res => console.log(res.data.name))
        .catch(err => console.log(err));
    } 
 */
    openShoutbox = () => {
        const data = {
            show: true
        }
        axios.put(`/shoutbox/${this.state.id}.json?auth=` + this.props.token, data)
        .then(res => window.location.reload(false))
        .catch(err => console.log(err));
    }

    refreshPage = () => {
        window.location.reload();
    }

    closeShoutbox = () => {
        const data = {
            show: false
        }
        axios.put(`/shoutbox/${this.state.id}.json?auth=` + this.props.token, data)
        .then(res => window.location.reload(false))
        .catch(err => console.log(err));
       
    }

    render(){

        return(
            <div className={classes.control_panel}>
                <OpenButton click={this.openShoutbox} className={classes.open}>Avaa Shoutbox</OpenButton>
                <CloseButton click={this.closeShoutbox} className={classes.close}>Sulje Shoutbox</CloseButton>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
    }
}


export default connect(mapStateToProps)(ControlShoutbox);