import * as actionTypes from './actionTypes';

export const changeStatePlay = (play) => {
    return{
        type: actionTypes.PLAY,
        play: play
    }
}

export const setCurrentProgram = (program) => {
    return{
        type: actionTypes.SET_PROGRAM,
        program: program
    }
}