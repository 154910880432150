import React, { Component } from 'react';

import classes from './EditProgram.module.css';
import Button from '../../UI/Buttons/Button';
import axios from '../../axiosdata';
import { connect } from 'react-redux';

class EditProgram extends Component {

    state = { 
        programName: this.props.program.program.programName,
        groupName: this.props.program.program.groupName,
        date: this.props.program.program.date,
        startTime: this.props.program.program.startTime,
        end_time: this.props.program.program.end_time,
        info: this.props.program.program.info,
    }

    saveData = () => {
       let data = {
            program: {
                programName: this.state.programName,
                groupName: this.state.groupName,
                date: this.state.date,
                startTime: this.state.startTime,
                end_time: this.state.end_time,
                info: this.state.info
            }
        }
        axios.put(`/programs/${this.props.program.id}.json?auth=` + this.props.token, data)
        .then(res => window.location.reload(false))
        .catch(err => console.log(err));
    }

    handleChangeProgramName = (event) => {
        this.setState({programName: event.target.value});
    }
    handleChangeGroupName = (event) => {
        this.setState({groupName: event.target.value});
    }
    handleChangeDate = (event) => {
        this.setState({date: event.target.value});
    }
    handleChangeStartTime = (event) => {
        this.setState({startTime: event.target.value});
    }
    handleChangeEndTime = (event) => {
        this.setState({end_time: event.target.value});
    }
    handleChangeInfo = (event) => {
        this.setState({info: event.target.value});
    }

    render() {

        return (
            <div className={classes.editForm}>
                <h2>Muokkaa</h2>
                <h3>Ohjelman nimi</h3>
                <input type="text" value={this.state.programName} onChange={this.handleChangeProgramName}/>              
                <h3>Ryhmän nimi</h3>
                <input type="text" value={this.state.groupName} onChange={this.handleChangeGroupName}/>              
                <h3>Päivämäärä</h3>
                <input type="date" value={this.state.date} onChange={this.handleChangeDate}/>
                <h3>Alkamisaika</h3>
                <input type="time" value={this.state.startTime} onChange={this.handleChangeStartTime}/>
                <h3>Loppumisaika</h3>
                <input type="time" value={this.state.end_time} onChange={this.handleChangeEndTime}/>
                <h3>Lisätietoa</h3>
                <textarea type="textarea" value={this.state.info} onChange={this.handleChangeInfo}/>              
                <Button click={this.saveData}>Tallenna</Button><Button click={this.props.close}>Peruuta</Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.token !== null,
    }
}


export default connect(mapStateToProps)(EditProgram);