import React, {Component} from 'react';

import './Content.css'

class Info extends Component{

    componentDidMount() {
        window.scrollTo(0, 0)
      }
    
    render(){

        return(
            <div className="Content_Wrapper">
                <div className="content_box">
                    <h1>Info</h1>
                    <div className="text_box">
                        <p>
                            Tulossa pian!
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Info;