import React from 'react';

import './Footer.css';

import IonIcon from "@reacticons/ionicons";

const currentYear = new Date().getFullYear();

const footer = (props) => (
    <div className="footerWrapper">
        <div className="footer">
            <div className="footerBox_1">
                <h2>Ota yhteyttä</h2>
                <a href="/yhteystiedot">Yhteystiedot</a>
            </div>
            <div className="footerBox_1">
                <h2>Lisää</h2>
                <a href="/admin">Admin</a>
            </div>
            <div className="footerBox_2">
                <h2>Seuraa somessa</h2>
                <a href="https://www.facebook.com/RadioWapina"><IonIcon className={"footerIcon"} name={"logo-facebook"} size={"large"}/></a>
                <a href="https://twitter.com/RadioWapina/"><IonIcon className={"footerIcon"} name={"logo-twitter"} size={"large"}/></a>
                <a href="https://www.instagram.com/wapinaradio/"><IonIcon className={"footerIcon"} name={"logo-instagram"} size={"large"}/></a>
            </div>
        </div>
        <div>
            <span>Copyright &copy; {currentYear} Wapina - Design by Eemil Vähä - Webmaster R46</span>
        </div>
    </div>
)

export default footer;