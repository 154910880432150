import React, { useRef, useState, useEffect } from 'react';
import classes from './Shoutbox.module.css';

//import firebase from 'firebase/app';
//import 'firebase/firestore';
//import 'firebase/auth';
//import 'firebase/analytics';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import axios from '../../axiosdata';
import Button from '../../UI/Buttons/GoogleButton';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyDsQYPPKsiX_5fE0v3DHm7pLQeKtc92lr4",
  authDomain: "radio-wapina.firebaseapp.com",
  databaseURL: "https://radio-wapina-default-rtdb.firebaseio.com",
  projectId: "radio-wapina",
  storageBucket: "radio-wapina.appspot.com",
  messagingSenderId: "181359050460",
  appId: "1:181359050460:web:e9b7670249cebb3faf3e19",
  measurementId: "G-HSFPQGKP8Y"
};

/* var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:  process.env.REACT_APP_FIREBASE_AUHT_DOMAIN,
  databaseURL:  process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID ,
  measurementId:  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}; */
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}


const firestore = firebase.firestore();
const auth = firebase.auth();


function Shoutbox(props) {
/*   var chatHistory = document.getElementById("messageBody");
  chatHistory.scrollTop = chatHistory.scrollHeight; */
  const [user] = useAuthState(auth);

  return (
    <div>
        { props.showShoutbox ?  
        <div>
          { user ? 
          <ChatRoom isAuthenticated={props.isAuthenticated} /> 
          : <div className={classes.dummyChat}>    
              <div className={classes.dummyChatTop}/>
              <div className={classes.dummyMessage}>
                  <SignIn />
              </div>
              <div className={classes.dummyChatBottom}/>
            </div>
          } 
        </div> 
          : <div className={classes.dummyChat}>    
              <div className={classes.dummyChatTop}/>
                <div className={classes.dummyMessage}>
                  <h2>Shoutbox suljettu!</h2>
                </div>
              <div className={classes.dummyChatBottom}/>
            </div>
          }
    </div>
  );
}

function SignIn() {

  const [showRules, setShowRules] = useState(false);

  const changeShowRulesState = () => {
    showRules ? setShowRules(false) : setShowRules(true);
  }

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <div className={classes.signInContainer}>
      <div className={classes.infoText}>Kirjaudu sisään Google-tililläsi. Kirjautumalla hyväksyt kaikki säännöt ja käyttöehdot.</div>
      <div className={classes.signInButton} >
        <Button click={signInWithGoogle}>Sign in with Google</Button>
      </div>

      <div className={classes.rules}>
        <h3>Säännöt ja käyttöehdot</h3>
          {showRules ? 
          <div>
            <ul>
              <li>Kirjoita jotain mukavaa, älä häiriköi tai spämmi.</li>
              <li>Jos et kekski mitään järkevää sanottavaa keskity kuuntelemaan radiosta tulevia loistavia ohjelmia.</li>
              <li>Mitään loukkaavaa sisältöä ei sallita.</li>
              <li>Sivuston ylläpidolla on mahdollisuus nähdä sähköpostiosoitteesi kirjauduttuasi Shoutboxiin Google-tililläsi.</li>
              <li>Sivuston ylläpidolla on oikeus estää shoutboxin käyttäjä, mikäli kyseisen henkilön katsotaan rikkoneen shoutboxin sääntöjä.</li>
            </ul>
          </div>
          : null}
          {showRules ? <div className={classes.showRules} onClick={changeShowRulesState}>Piilota</div> : <div className={classes.showRules} onClick={changeShowRulesState}>Näytä...</div>}
      </div>
    </div>
  )

}

function SignOut() {
  return auth.currentUser && (
    <Button className="sign-out" click={() => auth.signOut()}>Sign out</Button>
  )
}


function ChatRoom(props) {

  const [showMessagesCount, setShowMessagesCount] = useState(15);
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limitToLast(showMessagesCount);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [formValueUser, setFormValueUser] = useState('');
  const [userColor, setUserColor] = useState('');
  const [adminUser, setAdminUser] = useState(false);
  const time = new Date().toLocaleString();
  const [warningMessage, setWarningMessage] = useState('')
  const [showWarningMessage, setShowWarningMessage] = useState(false)
  const [shoutboxState, setShoutboxState] = useState(false);
  const [shoutboxStateScroll, setShoutboxStateScroll] = useState(true);
  const [userAuthState, setUserAuthState] = useState(false);
  const [userAuth] = useAuthState(auth);

  useEffect(() => {
    scrollDown();
    checkIfStudioUser();
    checkIfShoutboxOpen();

  })

  const checkMessageContent = () => {
    let str = formValue;
    let user = formValueUser;
    let valid = true;

/*     function hasWhiteSpace(s) {
      
    }
    if (hasWhiteSpace(user)){
      valid = false;
      setWarningMessage('Käytä välilyönnin sijasta esimerkiksi _ tai -')
      setShowWarningMessage(true)
    }
     */

    function hasTooManyWhiteSpaces(s){
      let state = true;
      for(let i = 0, whitespaces = 0; i < s-1; i++){
      }
      if(s.trim().length < 2){
        state = false;
      }
      if (s.length === 0 || s.length === 1){
        state = false;
      }
      return state;
    }

    if (!hasTooManyWhiteSpaces(user)){
      valid = false;
      setWarningMessage('Anna kunnon nimimerkki! (Vähintään 2 merkkiä ja max 40)')
      setShowWarningMessage(true)
    }

    for(let i = 0, count = 0, mcount = 0; i < str.length; i++){
        if(str[i] === 'M' || str[i] === 'm' ){
          mcount++;
        } 
        if(mcount >= 10){
          setWarningMessage('Älä spämmi!')
          setShowWarningMessage(true)
          valid = false;
        }
       if(count > 200){
          setWarningMessage('Max 200 merkkiä!')
          setShowWarningMessage(true)
          valid = false;
       }else{
          count++;
       }
    }
    return valid;
  }

  const checkIfShoutboxOpen = async () =>{
   
    userAuth ? setUserAuthState(true) : setUserAuthState(false)

    axios.get('/shoutbox.json')
    .then(res => {
      setShoutboxState(res.data.show.show)
    })
    .catch(err => console.log(err));   
    return shoutboxState;
  } 
  const sendMessage = async (e) => {
    e.preventDefault();
  /*   let open = await checkIfShoutboxOpen(); */
   /*  let valid = checkMessageContent(); */
    if(shoutboxState && checkMessageContent()){
      const { uid, photoURL } = auth.currentUser;
      await messagesRef.add({
          user: formValueUser,
          text: formValue,
          time: time,
          color: getRandomColor(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid,
          photoURL,
          auth: userAuthState
      })
  
      setFormValue('');
      /* setFormValueUser(''); */
      scrollDown();
      setShowWarningMessage(false)
      await checkIfShoutboxOpen();
    } else{
      if(shoutboxState === false){
        setWarningMessage('Shoutbox suljettu')
      }
    }
  }

  const checkIfStudioUser = () =>{
    const {uid} = auth.currentUser;
    if (uid === 'Zd2WJ28LMHfhCAvfvSLMO75z3mw2') {
      setFormValueUser('Studio');
      setAdminUser(true)
    } else if (uid === '4tjV4v8tQgQLaxaBW2516CfwMU03') {
      setFormValueUser('🔥Mestari🔥');
      setAdminUser(true)
    }
  }

  const scrollDown = () => {
    var chatHistory = document.getElementById("messageBody");
    if(shoutboxStateScroll){
      chatHistory.scrollTop = chatHistory.scrollHeight;
    } else {
      scrollUp();
    }
  }

  const scrollUp = () => {
    var chatHistory = document.getElementById("messageBody");
    chatHistory.scrollDown = chatHistory.scrollHeight;
  }


  const showMoreMessages = (event) => {
    event.preventDefault();
    let currentCount = showMessagesCount;
    let increacedCount = currentCount + 15;
    setShowMessagesCount(increacedCount);
    setShoutboxStateScroll(false);
  }

  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var randColor = '#';
    for (var i = 0; i < 6; i++) {
        randColor += letters[Math.floor(Math.random() * 16)];
    }
    setUserColor(randColor)
     return {
        color: randColor
    }; 
  }
  
  return (
    <div className={classes.shoutbox}>
        <div className={classes.signOut}>
            <SignOut/>
        </div>
     
        <div className={classes.messages_section} id="messageBody">
            {props.isAuthenticated || adminUser ?
              <div className={classes.showMoreMessages} id="moremessages">
                <div className={classes.tag} onClick={showMoreMessages}>
                  Näytä lisää
                </div>
              </div>
            : null}
            {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} isAuthenticated={props.isAuthenticated}/>)}
            <span ref={dummy}></span>
        </div>
        <div className={classes.form_section_background}>
            <form onSubmit={sendMessage} className={classes.form_section}>
                <input value={formValueUser} onChange={(e) => setFormValueUser(e.target.value)} placeholder="Nimimerkki (max 40 merkkiä)" className={classes.username_input} />
                <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Say something nice (max 200 merkkiä)" className={classes.text_input}/>
                {showWarningMessage ? <div className={classes.warningMessage}>{warningMessage}</div> : null}
                  { formValue.length > 0 ? 
                    <div className={classes.charactersLeft}>
                      Merkkejä jäljellä {200 - formValue.trim().length}
                      {formValue.trim().length > 150 ? ' - Riittää jo! Ei tänne mitää esseetä tarvi kirjottaa!' : null}
                    </div>
                    :
                    null
                  }
                <button type="submit" disabled={
                  !formValue 
                  || !formValueUser 
                  || formValue.length > 200
                  || formValueUser.length > 40 
                  || formValue.length < 2 
                  || formValue === '  '
                  || formValueUser === '  '
                  } className={classes.shout_button}>Shout!</button>

            </form>
        </div>
    </div>
  )
}



function ChatMessage(props) {

    const { text, user, time, color, uid } = props.message;
    
    let content = <div></div>; 

    const breakString = (str, limit) => {
      let brokenString = '';
      for(let i = 0, count = 0, mcount = 0; i < str.length; i++){
          if(str[i] === 'M' || str[i] === 'm' ){
            mcount++;
          } 
          if(mcount >= 18){
            mcount = 0;
            count = 0;
            brokenString += '\n';
          }
          if(str[i] === ' ' && count < limit){
             count = 0;
          }
          if(count >= limit){
            count = 0;
            brokenString += str[i] + "-" +'\n';
          }
          else{
            count++;
            brokenString += str[i];
         }
      }
      return brokenString;
   }

    if(uid === '4tjV4v8tQgQLaxaBW2516CfwMU03'){
      content = (
        <div className={classes.message}>
          <div className={classes.username}>
          🔥Mestari🔥:
          </div>   
          <div className={classes.text}>
              {text}
          </div>   
          <div className={classes.id}>
              {props.isAuthenticated ? '('+uid+')': null}
          </div>   
          <div className={classes.time}>
              {time}
          </div>   
        </div>
      )
    }
    
    else if(uid === 'Zd2WJ28LMHfhCAvfvSLMO75z3mw2'){
      content = (
        <div className={classes.message}>
          <div className={classes.usernameAdmin}>
              Studio:
          </div>   
          <div className={classes.text}>
              {text}
          </div>   
          <div className={classes.id}>
              {props.isAuthenticated ? '('+uid+')': null}
          </div>   
          <div className={classes.time}>
              {time}
          </div>   
        </div>
      )
    } else {
      content = (
        <div className={classes.message}>
          <div className={classes.username} style={color}>
              {user}:
          </div>   
          <div className={classes.text}>
          {breakString(text, 45)}
          </div>   
          <div className={classes.id}>
              {props.isAuthenticated ? '('+uid+')': null}
          </div>   
          <div className={classes.time}>
              {time}
          </div>   
        </div>
      )
    }

    return (
    <div>
      {content}
    </div>
    )
}

  export default Shoutbox;