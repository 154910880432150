import React, { Component } from 'react';

import classes from './AdminSettings.module.css';

import SmallTriangleDown from '../../UI/Buttons/SmallTriangleDown';
import SmallTriangleRight from '../../UI/Buttons/SmallTriangleRight';
import SettingsButton from '../../UI/Buttons/SettingsButton';
import ImageUploader from '../Sponsors/ImageUploader';
import ShoutboxControl from './ShoutboxControl';
import DataUploder from '../Calendar/DataUploader';
import Phonenumber from '../Information/phonenumber';


class AdminSettings extends Component{

    render() {
        return(
            <div className={classes.settings}>
                <h3>{this.props.functionality}</h3>
                {this.props.show ? <SettingsButton click={this.props.click}><SmallTriangleDown/></SettingsButton> : <SettingsButton click={this.props.click}><SmallTriangleRight/></SettingsButton>}
                {(this.props.setting === 'sponsor' && this.props.show) ? <ImageUploader/> : null}
                {(this.props.setting === 'shoutbox' && this.props.showShoutbox) ? <ShoutboxControl/> : null}
                {(this.props.setting === 'calendar' && this.props.showCalendar) ? <DataUploder/> : null}
                {(this.props.setting === 'phonenumber' && this.props.showPhonenumber) ? <Phonenumber/> : null}
            </div>
        );
    }
}

export default AdminSettings;