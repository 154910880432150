import React, { Component } from 'react';

import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import Logout from '../../UI/Buttons/SettingsButton3';
import IonIcon from "@reacticons/ionicons";
import classes from './NavigationItem/NavigationItem.module.css'



class NavigationItems extends Component {



    render(){
        let content = (
            <ul className="NavigationItems">
                <NavigationItem link="/"  exact>Etusivu</NavigationItem>
                <NavigationItem link="/juontaminen">Juontajille</NavigationItem>
                <NavigationItem link="/yhteystiedot">Yhteistyökumppaneille</NavigationItem>
                <NavigationItem link="/podcast">Podcast</NavigationItem>
                <li className={classes.NavigationItem}><a href={"https://kide.app/products/186452f4-dc83-40d9-97ed-ba93818a618a"} target="_blank" rel="noopener noreferrer">Puoti</a></li>
                <div className={"SocialBar"}>
                    <a href="https://www.facebook.com/RadioWapina"><IonIcon className={"logo"} name={"logo-facebook"} size={"large"}/></a>
                    <a href="https://twitter.com/RadioWapina/"><IonIcon className={"logo"} name={"logo-twitter"} size={"large"}/></a>
                    <a href="https://www.instagram.com/wapinaradio/" className="last_element logo"><IonIcon name={"logo-instagram"} size={"large"}/></a>
                </div>
                { this.props.isAuthenticated ? <Logout className="logout" link="/logout">Kirjaudu ulos</Logout> : <div></div>}
            </ul>
            );
        

        return (
            <div>
                {content}
            </div>
        );
    }
}
export default NavigationItems;